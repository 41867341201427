body{
    overflow-x: hidden;
}

.mobileVisible{
    display: none;
}

a:visited{
    color: inherit;
}
a:link{
    text-decoration: inherit;
    color: white;
}
.Header{
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    align-items: center;
    justify-content: space-between;
    font-family: 'Courier New', Courier, monospace;
}
.Header > img{
    cursor: pointer;
}
.Nav{
    background-image: linear-gradient(to right, darkblue, darkblue, blue);
    color: white;
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    align-items: center;
    justify-content: space-around;
}
.fixed-nav{
    background-image: linear-gradient(to right, darkblue, darkblue, blue);
    color: white;
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
.search{
    display: flex;
    justify-content: space-between;
}
.search > img{
    padding-left: 5%;
}
.search > input{
    background-color:rgba(0, 0, 0, 0);
    color: white;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom-width: 1px;
    border-color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    font-size: larger;
}
.newTools{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: darkblue;
    padding: 2%;
    margin: 5%;
}
.newTools > a{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 3%;
    padding-top: 3%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 300%;
}

.tools{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.tools > button{
    margin: 24%;
    padding: 1%;
    color: black;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgba(0, 0, 0, 0.0);
    border-radius: 45%;
}
.tools > button:Hover{
    margin: 24%;
    padding: 1%;
    color: black;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 45%;
}

#lt{
    position: absolute;
    margin-right: 120%;
    z-index: 10;
    cursor: pointer;
}
#gt{
    position: absolute;
    margin-right: -72%;
    z-index: 10;
    cursor: pointer;
}
.toolBox > img{
    width: 192px;
    cursor: pointer;
}
.toolBox > button{
    font-family: Arial, Helvetica, sans-serif;
    background-color: darkred;
    padding: 10%;
    color: white;
    font-size: medium;
    width: max-content;
    margin-left: 0%;
    cursor: pointer;
}

.toolBox{
    border-right-width: 1px;
    border-right: solid;
    border-right-color: rgba(0, 0, 0, 0.25);
    color: black;
    font-family: Arial, Helvetica, sans-serif; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

#newToolsContainer > .toolBox{
    padding-right: 6%;
    padding-left: 6%;
}

.starContainer{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20%;
    font-size: xx-large;
}
.starContainer > a{
    font-size: large;
    padding: 4%;
    vertical-align: baseline;
}

.blueStar{
    color: blue;
}
.greyStar{
    color: grey;
}

.riggingServices, .AboutUs, .ContactForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: darkblue;
    padding: 2%;
}


.ContactUs{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: White;
    padding: 2%;
    background-image: linear-gradient(to right, darkblue, darkblue, blue);

}
.aboutContent{
    text-align: center;
}
.aboutContent > h4{
    padding: 2%;
}
.riggingServices > img, .aboutContent > img{
    padding: 2%;
}
.riggingServices > a, .AboutUs > a, .ContactUs, .ContactForm > a{
    padding: 2%;
    padding-bottom: 3%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 300%;
}
.riggingContent, .aboutContent{
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
}

.dots{
    display: flex;
    position: relative;
    margin-top: -5%;
    margin-left: 50%;
    color: rgba(0, 0, 0, 0);
}
.dot{
  background-color: rgba(255, 255, 255, 0.5); 
  border-radius: 50%;
  margin: 0.5%;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.dotCurrent{
  background-color: rgba(255, 255, 255, 0.75); 
  border-radius: 50%;
  margin: 0.5%;
  display: block;
  width: 16px;
  height: 16px;
}

.gallery{
    height: 600px;
    text-align: center;
}
.gallery > .active{
    max-height: 600px;
    animation: fadeIn 2s;
    width: 100%;
}

@keyframes fadeIn {
    0% { opacity: 0.1; }
    100% { opacity: 1; }
  }

.phoneIcon{
    font-size: x-large;
    padding-right: 2%;
    vertical-align: middle;
}

.emailIcon{
    font-size: 750%;
    padding-right: 2%;
    vertical-align: middle;
}

.contactButton{
    text-align: center;
}

.contactContent{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    font-size: x-large;
}
.contactContent > div{
    padding: 2%;
    padding-bottom: 10%;

}

.map{
    border-radius: 50%;
}

.Footer{
    display: flex;
    flex-direction: column;
}
.footerLinks{
    
    font-size: x-large;
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.Footer > .footerLinks :link{
    color: black;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    border-right: rgba(0, 0, 0, 0.25);
    border-right-width: 2px;
    border-right-style: solid;
}

.flavor{
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
}

.copyrights{
    text-align: center;
    font-size: x-large;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
}

.socialMedia{
    display: flex;
    justify-content: end;
    font-size: x-large;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 5%;
}

.socialMedia > a{
    padding-left: 2%;
}

.ContactForm{
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-family: 'Courier New', Courier, monospace;
    align-items: center;
}
.contactFormContents{
    color: black;
    padding: 2%;
    border-color: darkblue;
    border-width: 1%;
    border-radius: 2%;
    font-size: x-large;
    display: flex;
    flex-direction: column;  
 }
.contactFormContents > form > div{
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    width: 100%;
}

button {
    background-color: darkred;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 24px;
    margin-left: 80%;
}

.storeContent{
    margin-top: 2%;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(256px, 1fr));
    width: 100%;
}
.storeContent >*{
    border-style: none;
    margin-bottom: 25%;
}
.topInfo{
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 15%;
    padding-top: 2%;
    padding-bottom: 5%;
}
.topInfo > img{
    width: 25%;
}
.leftInfo{
    display: flex;
    flex-direction: column;
    width: 25%;
}
.ebayButtons > a{
    text-align: center;
    background-color: darkblue;
    color: white;
    padding: 2%;
    margin: 2%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: large;
    width: 100%;
    cursor: pointer;
}

#voeb{
    background-color: blue;
    width: 100%;
}
#pdesc{
    padding-left: 2%;
    width: 98%;
    height: 512px;
}

.ebayButtons{
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.filterbar{
    text-align: end;
    padding: 1%;
    padding-right: 2%;
    font-size: large;
    font-family: Arial, Helvetica, sans-serif;
}
.filterbar > *{
    margin-left: 1%;
}

.icount{
    padding-left: 2%;
}

.mobileVisible{
    display: none;
}

.picgallery{
    align-items: end;
    align-items: flex-start;
    display: flex;
}
.picgallery > .active{
    width: 600px;
    animation: fadeIn 2s;
}

.gdots{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-left: 2%;
    color: rgba(0, 0, 0, 0);
}
.gdots>* {
    flex: 0 0 calc(100% - 10px);
    max-width: calc(100% - 10px);
    box-sizing: border-box;
    padding-bottom: 3%;
  }
.gdot{
  background-color: rgba(255, 255, 255, 0.5); 
  display: block;
  width: 128px;
  height: 128px;
  cursor: pointer;
}
.gdotCurrent{
  background-color: rgba(255, 255, 255, 0.75); 
  margin: 0.5%;
  display: block;
  width: 128px;
  height: 128px;
  border-color: rgba(0, 0, 0, 0.25);
  border: 2%;
  border-style: solid;
  border-radius: 5%;
}
.etopInfo{
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 5%;
}

.equipment{
    text-align: center;
}
.equipment > a{
    font-size: xx-large;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 100%;
    display: block;
    color: darkblue;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    font-size: 300%;
}
.equipmentcontrols{
    display: flex;
    justify-content: center;
    vertical-align: middle;
    justify-content: space-evenly;
    width: auto;
    display: inline;
}
.equipmentcontrols > form{
    justify-content: center;
    padding: 2%;
    width: 100%;
    vertical-align: middle;
    display: inline;
}
.equipmentcontrols > form > label{
    padding-left: 2%;
    padding-right: 1%;
}
.equipmentcontrols > a{
    font-size: large;
    width: 100%;
    margin-right: 25%;
    color: white;
    background-color: darkblue;
    padding: 1%;
}
.equipment > .equipmentContent{
    width: auto;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
    border-style: none;
    padding-bottom: 2%;
    padding-top: 2%;
    text-align: center;
}
.equipment > .equipmentContent > .toolBox{
    border: none;
    align-items: center;
    margin-bottom: 8%;
    width: 25%;
}

.toolBox > h3{
   text-align: center;
}

.pageControls{
    justify-content: space-evenly;
    display: flex;
    font-size: large;
    cursor: pointer;
}

#pdesc > div:nth-child(1) :nth-child(2){
    text-decoration: underline;
    color: darkblue;
    cursor: pointer;
}

#upSelectBtn{
    padding: 5%;
    background-color: darkblue;
    color: white;
    cursor: pointer;             
    display: block;
    text-align: center;
}

.storeContent > form > *{
    margin-bottom: 10%;
}
#uploadedpics{
    display: flex;
    flex-basis: 21%;
    flex-wrap: wrap;
    width: 100%;
}
#uploadedpics > *{
    padding: 5%;
}

.loginform{
    align-content: center;
    padding: 5%;
    text-align: center;
}

.userform > form{
    display: flex;
    flex-direction: column;
    width: 25%;
    margin: auto;

    border-color: grey;
    border-style: solid;
    border: 1%;
    padding: 2%;
    border-radius: 2%;
}

.userform > form > div{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.loginControls{
    padding: 3%;
    text-align: center;
}
.loginControls > *{
    cursor: pointer;
    margin: auto;
    margin: 1%;
}
.userform > form > *{
    margin-top: 1%;
    text-align: start;
}

.userform > form > input[type='submit']{
    padding: 1%;
    margin-top: 5%;
    margin-right: 85%;
    text-align: center;
}
.userform > form > input[type='text'], .userform > form > select, .userform > form > input[type= 'password']{
    padding: 1%;
}

.userform > form > div > *{
    padding: 1%;
    text-align: start;
}

.navlogin > a{
    font-size: x-large;
    border-radius: 25px/50%;
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
    background-color: black;
    margin: auto;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    white-space: nowrap;
}

.userslist{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 50%;
    gap: 25%;
}

.userbox{
    display: flex;
    margin: 1%;
    padding: 5%;
    gap: 50%;
    width: 100%;
    place-content: space-between;
    background-color: lightblue;
    border-radius: 15px/25%;
}
.userbox a{
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    color: darkblue;
}
.userinfo{
    display: flex;
}
.usercontrols{
    display: flex;
    gap: 25%;
}

.usrcontorlbar{
    display: flex;
    width: 50%;
    gap: 15%;
    justify-content: center;
}
.usrcontorlbar button{
    white-space: nowrap;
    cursor: pointer;
}
.usrcontorlbar div{
    white-space: nowrap;
}
.viewdisplay{
    display: grid;
    grid-template-columns: auto;
    width: auto;
}
.view{
    display: flex;
}
.view > *{
    width: -webkit-fill-available;
    white-space: pre;
    padding-bottom: 1%;
    cursor: pointer;
    margin: 1em;
    text-align: center;
}
.view link{
    flex: 1;
    padding: 10px;
    max-width: 100px;
    cursor: pointer;
    color: black;
}
.viewcontrols{
    display: flex;
    gap: 10%;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 4%;
    width: 80%;
    place-items: center;
    text-align-last: center;
    justify-content: center;
}
.viewcontrols > *{
    display: flex;
    height: fit-content;
    gap: 1%;
    white-space: nowrap;
    align-items: center;
}
.clearFiltersBtn{
    all: initial;
    padding: 5%;
    background-color: darkred;
    color: white;
    border-radius: 20%/50%;
    white-space: nowrap;
    margin-left: 10%;
    margin-right: 10%;
    min-width: fit-content;
}
.toolBox > div{
    display: flex;
    font-size: x-large;
    padding: 5%;
    gap: 25%;
    justify-content: center;
    width: 100%;
}
.Views{
    width: 100%;
    justify-content: center;
}
.Views > a{
    color: darkblue;
    font-size: xxx-large;
    font-weight: bolder;
    padding: 5%;
    display: inline-flex;
    width: 100%;
    justify-content: center;
}

.storeContent > form > input{
    margin: 3;
    padding: 3;
}

@media only screen and (max-width: 600px) {
    .newTools{
        overflow-x: hidden;
    }
    .Header{
        justify-content: center;
    }
    .mobileHidden{
        display: none;
    }    
    .riggingServices > a, .AboutUs > a, .ContactUs, .ContactForm > a{
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 3%;
        padding-top: 3%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bolder;
        font-size: 300%;
        text-align: center;
    }

    .riggingServices > img, iframe{
        width: 100%;
    }

    .contactContent{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-evenly;
        font-size: x-large;
    }

    .map{
        border-radius: 0%;
    }
    .footerLinks{
        flex-direction: column;
        text-align: center;
    }
    .flavor > p {
        text-align: center;
    }
    .socialMedia{
        justify-content: center;
        padding-left: 0%;
        padding-right: 0%;
    }
    .socialMedia > a > img{
        width: 250%;
    }
    .socialMedia > a{
        padding-left: 10%;
        padding-right: 10%;
        padding-top: 2%;
        padding-bottom: 2%;
        justify-content: center;
    }
    .tools{
        translate: -20%;
    }

    #lt{
        position: absolute;
        margin-right: -8%;
        z-index: 10;
        cursor: pointer;
    }
    #gt{
        position: absolute;
        margin-right: -24%;
        z-index: 10;
        cursor: pointer;
    }
    
    .leftInfo{
        align-items: center;
       text-align: center;
    }

    .ebayButtons{
        text-align: center;
        align-items: center;
        width: 100%;
    }

    .topInfo{
        flex-direction: column;
    }
    .topInfo > img{
        width: 100%;
    }
    
    .contactFormContents > form > div{
        flex-direction: column;
        padding: 0%;
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .contactFormContents{
        margin-bottom: 50%;
    }
    .sendButton > button{
        margin-left: 0%;
    }
    .storeContent{
        margin-bottom: 50%;
        text-align: center;
    }
    .storeContent > .toolBox{
        align-items: center;
        width: 100%;
    }
    .storeContent > .toolBox > button{
        width: 100%;
    }
    .storeContent > .toolBox{
        padding-left: 0%;
        padding-right: 0%;
    }
    .ContactForm{
        width: 100%;
        padding: 2% 0%;
    }
    .filterbar {
        font-size: large;
        padding-top: 5%;
    }
    .filterbar > *{
        padding: 1%;
    }
    .filterbar > select{
        font-size: large;
    }

    .mobileVisible{
        display: block;
    }
    #menuHidden{
        display: none;
    }
    #mobilem{
        justify-content: space-between;
        margin-top: initial;
        width: 100%;
        font-size: x-large;
    }
    .Nav, .fixed-nav{
        padding-top: 10%;
        padding-bottom: 10%;
        width: 100%;
    }
    .Nav > *, .fixed-nav > *{
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .Header{
        width: 100%;
    }
    .navlogin{
        background-color: black;
        margin-bottom: 100%;
        text-align: left;
    }
    .navlogin > a{
        font-size: 90%;
        background-color: initial;
        border-radius: initial;
        padding: initial;
        padding-top: 5%;
        padding-bottom: 5%;
        margin: auto;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        white-space: nowrap;
        display: block;
        padding-left: 5%;
    }

    .equipmentContent{
        flex-direction: column;
        align-content: center;
    }
    .equipment > .equipmentContent > .toolBox{
        width: 100%;
    }
    .equipmentContent > .toolBox > h3{
        width: 100%;
    }
    .equipmentContent > .toolBox > button{
        width: 100%;
        margin-bottom: 10%;
        padding: 10%;
    }
    .equipmentContent > .toolBox > img{
        width: 100%;
    }
    .equipmentContent > .toolBox > *{
        padding-top: 2%;
        padding-bottom: 2%;
        margin: 1%;
    }
    .equipmentContent > .toolBox > div > *{
        width: 300%;
    }

    .equipmentcontrols{
        display: flex;
        flex-direction: column;
    }
    .equipmentcontrols > form{
        width: 90%;
        align-self: center;
    }
    .equipmentcontrols > form > *{
        width: 90%;
        margin: 2%;
        padding: 1%;
        font-size: large;
    }

    .equipmentcontrols > form > input[type='submit']{
        width: 100%;
        margin: 2%;
        background-color: darkred;
        color: white;
        font-size: large;
        padding: 5%;
    }
    .equipmentcontrols > a{
        padding-top: 5%;
        padding-bottom: 5%;
        margin: initial;
        padding-left: 0%;
        padding-right: 0%;
    }

    .toolBox > div{
        padding: initial;
    }

    .productContent > .etopInfo{
        flex-direction: column-reverse;
    }
    .picgallery>.active {
        animation: fadeIn 2s;
        width: 100%;
    }
    .picgallery{
        flex-direction: column;
    }
    .picgallery > .gdots{
        flex-flow: nowrap;
        overflow-y: scroll;
        margin: 2%;
        width: fit-content;
    }
    .picgallery > .gdots > *{
        flex-grow: 1;
        flex-shrink: 1;
        width: 40%;
        height: auto;
        margin-left: 1%;
        margin-right: 1%;
    }
    .productContent > .etopInfo > .leftInfo{
        width: 100%;
    }
    .productContent > .etopInfo > .leftInfo > #pdesc{
        text-align: left;
    }
    .storeContent > form > *{
        width: 90%;
        padding-top: 2%;
        padding-bottom: 2%;
        justify-content: center;
    }

    #uploadedpics{
        justify-content: center;
    }

    #uploadedpics > *{
        padding: initial;
        width: 100%;
        height: fit-content;
    }
    .usrcontorlbar{
        width: 100%;
        flex-wrap: wrap;
    }
    .usrcontorlbar > *{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5%;
        width: 100%;
    }
    .usercontrolbar{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .userslist{
        width: fit-content;
        margin: auto;
    }
    .usrcontorlbar div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .usrcontorlbar div > *{
        padding: 3%;
    }
    .userbox{
        border-radius: 10%/15%;
        display: flex;
        margin: 5%;
        padding: 5%;
        flex-direction: column;
        align-items: center;
    }
    .userbox > *{
        margin-bottom: 5%;
        padding: 15%;
        font-size: large;
    }
    .usercontrols{
        padding: 10%;
        gap: 100%;
        justify-content: center;
    }
    .userform>form{
        width: 100%;
    }
    .userform>form>*{
        margin-bottom: 2%;
    }
    .userform>form>input[type=submit]{
        width: 100%;
        margin-right: auto;
        padding: 5%;
        color: white;
        font-size: large;
        background-color: darkred;
    }
    .productContent > .topInfo > .leftInfo{
        width: 100%;
    }
    .productContent > .topInfo{
        padding: 0%;
    }
    .ebayButtons>a{
        padding: initial;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    #pdesc{
        padding-left: 0%;
    }
    .equipment > .equipmentContent> .toolBox{
        padding: initial;
    }

    #upSelectBtn{
        padding: initial;
        padding-top: 5%;
        padding-bottom: 5%;
        width: 100%;
    }
    #mobilem{
        padding-left: 10%;
    }
    .search>img{
        padding: initial;
    }
    .storeContent > form > input{
        margin-left: initial;
        margin-right: initial;
        padding-left: initial;
        padding-right: initial;
    }
    .storeContent > .toolBox > h3{
        width: 100%;
    }

    .newTools > a{
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 15%;
        padding-top: 15%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bolder;
        font-size: 300%;
    }

    .riggingServices > a, .AboutUs > a, .ContactUs, .ContactForm > a{
        padding-left: 2%;
        padding-right: 2%;
        padding-bottom: 15%;
        padding-top: 15%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bolder;
        font-size: 300%;
        text-align: center;
    }
}